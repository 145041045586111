// -- IMPORTS
import { addFileExtensionPrefix } from 'senselogic-gist'

// -- VARIABLES

export let imageFilePathPrefix = 'https://xwrmyknjjmeurtpaqvxis.supabase.co/storage/v1/object/public/global';
export let imageFilePathSuffix = '';

// -- FUNCTIONS

export function getFilePathType(
    filePath
    )
{
    let fileType = filePath.split( '.' ).pop();
    switch ( fileType.toLowerCase() )
    {
        case 'jpg':
        case 'JPG':
        case 'jpeg':
        case 'JPEG':
            return 'jpg';
        case 'png':
        case 'PNG':
            return 'png';
        case 'svg':
        case 'SVG':
            return 'svg';
        case 'mp4':
        case 'MP$':
            return 'mp4';
        case 'avif':
        case 'AVIF':
            return 'avif';
        case 'webp':
        case 'WEBP':
            return 'webp';
        default:
            return fileType.toLowerCase()
    }
}

// ~~

export function getFilePathWithLowerCaseExtension(
    filePathWithExtension
    )
{
    let filePathPartArray = filePathWithExtension.split( '.' );
    let fileExtension = filePathPartArray.pop();
    let fileWithPath = filePathPartArray.join('.');

    if ( fileExtension === 'jpg'
         || fileExtension === 'jpeg'
    )
    {
        return fileWithPath + ".jpg";
    }
    else if ( fileExtension === 'MP$' )
    {
        return fileWithPath + ".mp4";
    }
    else
    {
        return filePathWithExtension
    }
}

// ~~ 

export function getImagePath(
    filePath,
    fileSize = '1920',
    isAvif = true
    )
{
    if( filePath )
    {
        let encodedImagePath = filePath.replace( /\//g, '/' );
        encodedImagePath = getFilePathWithLowerCaseExtension( encodedImagePath );
        let originalFileExtension = getFilePathType( filePath );
        
        if ( filePath.includes( 'global' ) )
        {
            imageFilePathPrefix = 'https://xwrmyknjjmeurtpaqvxi.supabase.co/storage/v1/object/public'    
        }
        else
        {
            imageFilePathPrefix = 'https://xwrmyknjjmeurtpaqvxi.supabase.co/storage/v1/object/public/global'  
        }

        if ( originalFileExtension === 'webp' )
        {
            return imageFilePathPrefix + encodedImagePath + imageFilePathSuffix;
        }
        else if ( originalFileExtension === 'svg' )
        {
            return imageFilePathPrefix + encodedImagePath + imageFilePathSuffix;
        }
        else if ( fileSize )
        {    
            let imageWithFileSize = addFileExtensionPrefix( encodedImagePath, '.' + fileSize )

            return imageFilePathPrefix + imageWithFileSize + imageFilePathSuffix;
        }
        else
        {
            return imageFilePathPrefix + encodedImagePath + imageFilePathSuffix;
        }
    }
    else
    {
        return '';
    }
}
