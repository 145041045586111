<script>

    // -- IMPORTS

    import { getImagePath } from '$lib/filePath';
    import AdminEditButton from '$lib/component/element/AdminEditButton.svelte';
    import { enabledAdminView } from '$lib/store/adminStore.js';
    import { getLocalizedText } from 'senselogic-gist';
    import { languageTagStore } from '$lib/store/languageTagStore';
    import AnimatedContainer from '$lib/component/element/AnimatedContainer.svelte';
    import { getProcessedMultilineTranslatedText } from '../../base';
    import ContactButtons from '../element/ContactButtons.svelte';   

    export let managerArray
</script>

<style lang="stylus">
    // -- IMPORTS

    @import '../../../constant.styl';
    @import '../../../mixin.styl';

    // -- CLASSES

    .manager-section
    {
        border: 1px solid borderColor;
        padding: 5rem 1.5rem;

        display: flex;
        flex-direction: column;
        gap: 4rem;

        +media( desktop )
        {
            margin: 0;
            margin-bottom: 5rem;
            max-width: 77vw;
            border: none;
            padding-bottom: 0;
        }
    }

    .manager-card
    {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        align-items: flex-start;
        align-self: stretch;

        +media( desktop )
        {
            flex-direction: row;
            gap: 5rem;
        }
    }

    .manager-card.reverse
    {
        +media( desktop )
        {
            flex-direction: row-reverse;
        }
    }

    .manager-card-content
    {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        align-items: flex-start;
        align-self: stretch;

        +media( desktop )
        {
            width: 40.5rem

            flex: 1 0 0;
            gap: 2rem;
            justify-content: center;
        }
    }

    .manager-card-content-text
    {
        line-height: 2rem;
        font-size: 1.25rem;
        color: #BFC6CA;
    }

    .manager-card-heading-title
    {
        line-height: 3rem;
        font-size: 2rem;
        font-weight: 600;
        color: whiteColor;
    }

    .manager-card-image
    {
        height: auto;
        width: 23.75rem;
        aspect-ratio: 1;

        align-self: stretch;
        object-fit: cover;
        object-position: center;

        +media( desktop )
        {
            height: 40rem;
            width: 44.375rem;
        }
    }
</style>

<section class="manager-section">
    { #each managerArray as { id, name, position, imagePath, biography, imageSide, linkedinLink, emailAddress } }
        <AnimatedContainer>
            <div class="manager-card { imageSide === 'right' ? 'reverse' : '' }" class:is-admin-enabled={ $enabledAdminView }>
                <AdminEditButton
                    type={ "manager" }
                    slug={ id }
                />
                <div class="manager-card-image" 
                    style="
                        background: URL( { getImagePath( imagePath, '1920' ) } ) no-repeat center center / cover, 
                                    URL( { getImagePath( imagePath, '360' ) } ) no-repeat center center / cover; 
                        "
                />

                <div class="manager-card-content">
                    <div>
                        <p class="manager-card-heading-title">{ name }</p>
                        <p class="manager-card-content-text">{ getLocalizedText( position, $languageTagStore ) }</p>
                    </div>

                    <div class="description-text">
                        { @html getProcessedMultilineTranslatedText( biography || '', $languageTagStore ) }
                    </div>

                    <ContactButtons
                        { emailAddress }
                        { linkedinLink }
                    />
                </div>
            </div>
        </AnimatedContainer>
    { /each }
</section>
