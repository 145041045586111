<script>
    // -- IMPORTS

    import { getImagePath } from '$lib/filePath';
    import { getMap, getLocalizedText } from 'senselogic-gist';
    import { getProcessedMultilineTranslatedText } from '$lib/base';
    import { selectedPlan, selectedFee } from '$lib/store/planStore.js';
    import { languageTagStore } from '$lib/store/languageTagStore.js';
    import { textStore } from '$lib/store/textStore.js';
    import { navigate } from 'svelte-routing';
    import { onMount } from 'svelte';
    import { fetchData, getFormattedCurrencyValue } from '../../base';
    import { pageStore } from '../../store/pageDataStore';
    import Loading from './Loading.svelte';
    import { userLocationStore } from '../../store/userLocationStore';

    // -- VARIABLES

    export let redirect = false;
    let planArray = $pageStore.block.membershipPlan;
    let planByIdMap = [];
    let planByNameMap = [];
    let selectedTab = 'club';
    let isLoading = true;
    let userCurrency = 'eur';

    switch ( $userLocationStore.countryCode )
    {
        case 'BR':
            userCurrency = 'brl';
            break;
        case 'US':
            userCurrency = 'usd';
            break;
        default:
            userCurrency = 'eur';
    }

    // -- FUNCTIONS

    function handleSelectPlan(
        plan
        )
    {
        selectedPlan.set( plan );

        if ( redirect )
        {
            navigate( `/${ $languageTagStore }/become-member` );
        }
    }

    async function loadData()
    {
        try
        {
            isLoading = true

            let data =
                await fetchData(
                    '/api/plan',
                    {
                        method: 'POST' ,
                        body: JSON.stringify(
                            {
                                type: 'getPlanArray'
                            }
                        )
                    }
                );

            $pageStore.block.membershipPlan = data.planArray
            planArray = data.planArray
            planByIdMap = getMap( data.planArray, 'id' );
            planByNameMap = getMap( data.planArray, 'name' );
            selectedTab = data.planArray[ 0 ].id || 'club'
        }
        catch ( error )
        {
            console.error( 'Error:', error )
        }
        finally
        {
            isLoading = false;
        }
    }

    // -- STATEMENTS

    onMount(
        () =>
        {
           if ( planArray === undefined ) {
               loadData()
           }
           else
           {
                isLoading = false;
                planByIdMap = getMap( planArray, 'id' );
                planByNameMap = getMap( planArray, 'name' );
                selectedTab = planArray[ 0 ].id || 'club'
           }
        }
    )
</script>

<style lang="stylus">
    // -- IMPORTS

    @import '../../../constant.styl';
    @import '../../../mixin.styl';

    // -- ELEMENTS

    table
    {
        width: 100%;
        border-collapse: collapse;
    }

    th,
    td
    {
        color: darkGreyColor;
    }

    button
    {
        cursor: pointer;
    }

    // -- CLASSES

    .container
    {
        width: 100%;
        padding-top: 2.5rem;

        display: none;
        flex-direction: column;
        gap: 2rem;

        background-color: lightGoldColor;

        +media( desktop )
        {
            margin-top: 5rem;
            max-width: 77vw;

            display: flex;
        }
    }

    .title
    {
        text-align: center;
        color: darkGreyColor;

        +media( desktop )
        {
            line-height: 7.5rem;
            font-size: 6rem;
            font-weight: 250 !important;
            letter-spacing: 0.48rem;
            text-transform: uppercase;
        }
    }

    .toggle-buttons
    {
        height: 3.5rem;

        display: flex;
        gap: 1rem;
        justify-content: center;
        align-self: stretch;
    }

    .toggle-buttons > button
    {
        border: 1px solid darkGreyColor;
        padding: 0.75rem 1.25rem;

        background: lightGoldColor;

        line-height: 2rem;
        font-size: 1.25rem;
        color: darkGreyColor;
    }

    .toggle-buttons > button.is-active
    {
        background: darkGreyColor;

        color: lightGoldColor;
    }

    .text,
    .value
    .select-button
    {
        line-height: 2rem;
        font-size: 1.25rem;
    }

    .text
    {
        border-bottom: 1px solid goldColor500;
        border-right: 1px solid goldColor500;
        padding: 1rem 1.5rem;

        display: flex;
        gap: 1rem;
        align-items: flex-start;
        align-self: stretch;
    }

    .value
    {
        border-bottom: 1px solid goldColor500;
        border-right: 1px solid goldColor500;
        padding: 0.5rem 1rem;

        gap: 1rem;
    }

    .icon-value
    {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .icon
    {
        height: 2.5rem;
        width: 2.5rem;

        flex-shrink: 0;
    }

    .select-button
    {
        width: 100%;
        border: 1px solid darkGreyColor;
        padding: 0.75rem 1.25rem;

        display: flex;
        flex: 1 0 0;
        gap: 0.5rem;
        justify-content: center;
        align-items: center;

        background: lightGoldColor;
    }

    .select-button,
    .toggle-buttons button
    {
        transition: all 200ms ease-in-out;
    }

    .select-button:hover,
    .toggle-buttons button:hover
    {
        background: darkGreyColor;

        color: lightGoldColor;
    }

    .select-button.is-active
    {
        background: darkGreyColor;

        color: lightGoldColor;
    }

    .select-plan-cell
    {
        border-right: 1px solid goldColor500;
        padding: 1rem;
    }

    .plan-cell
    {
        min-width: 14rem;
        border-top: 1px solid goldColor500;
        border-bottom: 1px solid goldColor500;
        border-right: 1px solid goldColor500;
        padding: 0.5rem 0rem;

        font-size: 1.25rem;
        text-align: center;
        color: darkGreyColor;
    }

    .plan-cell.name
    {
        line-height: 1.75rem;
        font-weight: 700;
        letter-spacing: -0.025rem;
    }

    .plan-cell.value
    {
        line-height: 2rem;
    }

    .mobile-table
    {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        align-items: center;

        +media( desktop )
        {
            display: none;
        }
    }

    .mobile-container
    {
        width: 100vw;
        padding-bottom: 2.5rem;

        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        align-items: center;

        background: lightGoldColor;
    }

    .mobile-header
    {
        width: 100%;

        display: flex;
    }

    .mobile-button
    {
        border: 1px solid lightGoldColor;
        padding: 0.75rem 1.25rem;

        display: flex;
        flex: 1;
        gap: 0.5rem;
        justify-content: center;
        align-items: center;

        background: darkGreyColor;

        line-height: 1.5rem;
        font-size: 1rem;
        font-style: normal;
        letter-spacing: 0.1rem;
        text-transform: uppercase;
        color: lightGoldColor;
    }

    .mobile-button.is-active
    {
        background: lightGoldColor;

        color: darkGreyColor;
    }

    .mobile-content
    {
        width: 100%;

        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .mobile-content-item
    {
        width: 100%;
        padding: 1rem 1.5rem;

        display: flex;
        gap: 1rem;
    }

    .mobile-content-item:not( :last-child )
    {
        border-bottom: 1px solid #9D9782;
    }

    .mobile-title
    {
        display: initial;

        line-height: 3rem;
        font-size: 2rem;
        font-weight: 500;
        font-style: normal;
        letter-spacing: 0.08rem;
        text-align: center;
        text-transform: uppercase;
        color: lightGoldColor;
    }

    .mobile-select-fee
    {
        padding: 0rem 1.5rem;

        display: flex;
        gap: 0.5rem;
        justify-content: center;
        align-items: center;
        align-self: stretch;
    }

    .mobile-select-fee-button
    {
        border: 1px solid lightGoldColor;
        padding: 0.75rem 1.25rem;

        display: flex;
        flex: 1 0 0;
        gap: 0.5rem;
        justify-content: center;
        align-items: center;

        background: darkGreyColor;

        line-height: 1.5rem;
        font-size: 1rem;
        letter-spacing: 0.1rem;
        text-transform: uppercase;
        color: lightGoldColor;
    }

    .mobile-select-fee-button.is-active
    {
        background: lightGoldColor;

        color: darkGreyColor;
    }

    .mobile-select-plan
    {
        margin: 0 auto;
        width: 11rem;
    }

    .helper-text
    {
        margin-top: 0.5rem;
        width: 100%;
        max-width: 77vw;
        padding: 0 0.5rem;

        line-height: 1.125rem;
        font-size: 0.875rem;
        text-align: left;
        color: #BFC6CA;
    }
</style>

{ #if isLoading }
    <Loading />
{ :else }
    <div class="container">
        <h1 class="title">{ @html getLocalizedText( $textStore[ 'plans-section-heading' ] || '', $languageTagStore ) }</h1>

        <div class="toggle-buttons">
            <button class:is-active={ $selectedFee === 'monthlyFee' }
                on:click={ () => $selectedFee = 'monthlyFee' }
            >
                { getLocalizedText( $textStore[ 'per-month-button' ] || '', $languageTagStore ) }
            </button>

            <button
                class:is-active={ $selectedFee === 'annualFee' }
                on:click={ () => $selectedFee = 'annualFee' }
            >
                { getLocalizedText( $textStore[ 'per-year-button' ] || '', $languageTagStore ) }
            </button>
        </div>

        <table>
            <thead>
                <tr>
                    <th style="background: transparent;"></th>

                    { #each planArray as plan, index }
                        <th
                            class="plan-cell name"
                            style={ index === 0 ? 'border-left: 1px solid #9D9782;' : '' }
                        >
                            { getLocalizedText( plan.name || '', $languageTagStore ) }
                        </th>
                    { /each }
                </tr>
                <tr>
                    <th style="background: transparent;"></th>

                    { #each planArray as plan, index }
                        <th class="plan-cell value" style={ index === 0 ? 'border-left: 1px solid #9D9782;' : '' }>
                            { getFormattedCurrencyValue( plan[ $selectedFee ], userCurrency ) }
                            { getLocalizedText(
                                $textStore[
                                    $selectedFee === 'annualFee'
                                        ? 'euro-per-year-label'
                                        : 'euro-per-month-label'
                                    ]
                                    || '',
                                $languageTagStore
                                )
                            }
                        </th>
                    { /each }
                </tr>
            </thead>
            <tbody>
                { #if planArray.length > 0 }
                    { #each planArray[ 0 ].serviceArray as service, serviceIndex }
                        <tr>
                            <td class="text" style={ serviceIndex === 0 ? 'border-top: 1px solid #9D9782;' : '' }>
                                { #if planArray[ 0 ].serviceArray[ serviceIndex ].serviceType.iconImagePath }
                                    <div 
                                        class="icon" 
                                        style="
                                            background: URL( { getImagePath( planArray[ 0 ].serviceArray[ serviceIndex ].serviceType.iconImagePath, '1920' ) } ) no-repeat center center / cover, 
                                                        URL( { getImagePath( planArray[ 0 ].serviceArray[ serviceIndex ].serviceType.iconImagePath, '360' ) } ) no-repeat center center / cover; 
                                            "
                                    />
                                { /if }

                                { @html getProcessedMultilineTranslatedText( service.serviceType.text || '', $languageTagStore ) }
                            </td>
                            { #each Object.entries( planByNameMap ) as [ name, plan ] }
                                <td class="value">
                                    { #if plan.serviceArray[ serviceIndex ].serviceType.valueTypeId === 'boolean' }
                                        { #if plan.serviceArray[ serviceIndex ].value === 'true' }
                                            <div class="icon-value">
                                                <div class="gray-500-check-icon icon size-250"/>
                                            </div>
                                        { :else }
                                            { '' }
                                        { /if }
                                    { /if }

                                    { #if plan.serviceArray[ serviceIndex ].serviceType.valueTypeId === 'number' }
                                        <div class="icon-value">
                                            {
                                                plan.serviceArray[ serviceIndex ].serviceType.valuePreffix
                                                ? getLocalizedText(
                                                        plan.serviceArray[ serviceIndex ].serviceType.valuePreffix,
                                                        $languageTagStore
                                                    )
                                                : ''
                                            }
                                            {
                                                plan.serviceArray[ serviceIndex ].value
                                            }
                                            {
                                                plan.serviceArray[ serviceIndex ].serviceType.valueSuffix
                                                ? getLocalizedText(
                                                        plan.serviceArray[ serviceIndex ].serviceType.valueSuffix,
                                                        $languageTagStore
                                                    )
                                                : ''
                                            }
                                        </div>
                                    { /if }
                                </td>
                            {/each}
                        </tr>
                    {/each}
                    <tr class="select-plan-row">
                        <td></td>

                        { #each planArray as plan, index }
                            <td class="select-plan-cell" style={ index === 0 ? 'border-left: 1px solid #9D9782;' : '' }>
                                <button
                                    class:is-active={ plan.id === $selectedPlan?.id || '' }
                                    on:click={ () => handleSelectPlan( plan ) }
                                    class="select-button">{ getLocalizedText( $textStore[ 'select-button' ] || '', $languageTagStore ) }
                                </button>
                            </td>
                        { /each }
                    </tr>
                { /if }
            </tbody>
        </table>
    </div>

    <div class="mobile-table">
        <h1 class="mobile-title">{ @html getLocalizedText( $textStore[ 'plans-section-heading' ] || '', $languageTagStore ) }</h1>

        <div class="mobile-select-fee">
            <button class="mobile-select-fee-button"
                class:is-active={ $selectedFee === 'monthlyFee' }
                on:click={ () => $selectedFee = 'monthlyFee' }
            >
                { getLocalizedText( $textStore[ 'per-month-button' ] || '', $languageTagStore ) }
            </button>
            <button class="mobile-select-fee-button"
                class:is-active={ $selectedFee === 'annualFee' }
                on:click={ () => $selectedFee = 'annualFee' }
            >
                { getLocalizedText( $textStore[ 'per-year-button' ] || '', $languageTagStore ) }
            </button>
        </div>

        <div class="mobile-container">

            <div class="mobile-header">
                { #each planArray as plan }
                    <button
                        type="button"
                        class="mobile-button"
                        class:is-active={ selectedTab === plan.id }
                        on:click={ () => selectedTab = plan.id }
                    >
                            { getLocalizedText( plan.name, $languageTagStore ) }
                    </button>
                { /each }
            </div>

            <div class="mobile-content">
                <div class="mobile-content-item font-weight-700 font-size-125 line-height-175 color-dark-grey">
                    {
                        $selectedFee === 'annualFee'
                            ? getFormattedCurrencyValue( planByIdMap[ selectedTab ].annualFee, userCurrency ) + ' / An*'
                            : getFormattedCurrencyValue( planByIdMap[ selectedTab ].monthlyFee, userCurrency ) + ' / Mo*'
                    }
                </div>

                { #each planByIdMap[ selectedTab ].serviceArray as service }
                    { #if service.serviceValueType.id === 'boolean' }
                        { #if service.value === 'true' }
                            <div class="mobile-content-item font-weight-700 font-size-125 line-height-175 color-dark-grey text-align-left">
                                { #if service.serviceType.iconImagePath }
                                    <div 
                                        class="icon" 
                                        style="
                                            background: URL( { getImagePath( service.serviceType.iconImagePath, '1920' ) } ) no-repeat center center / cover, 
                                                        URL( { getImagePath( service.serviceType.iconImagePath, '360' ) } ) no-repeat center center / cover; 
                                            "
                                    />
                                { /if }

                                { @html getProcessedMultilineTranslatedText( service.serviceType.name || '' ) }
                            </div>
                        { /if }
                    { /if }

                    { #if service.serviceValueType.id === 'number' }
                        { #if Boolean( service.value ) }
                            <div class="mobile-content-item font-weight-700 font-size-125 line-height-175 color-dark-grey text-align-left">
                                { #if service.serviceType.iconImagePath }
                                    <div 
                                        class="icon" 
                                        style="
                                            background: URL( { getImagePath( service.serviceType.iconImagePath, '1920' ) } ) no-repeat center center / cover, 
                                                        URL( { getImagePath( service.serviceType.iconImagePath, '360' ) } ) no-repeat center center / cover; 
                                            "
                                    />
                                { /if }

                                <span>
                                    { @html getProcessedMultilineTranslatedText( service.serviceType.name || '' ) }
                                    {
                                        service.serviceType.valuePrefix
                                        ? getLocalizedText( service.serviceType.valuePrefix, $languageTagStore )
                                        : ''
                                    }
                                    {
                                        service.value
                                    }
                                    {
                                        service.serviceType.valueSuffix
                                        ? getLocalizedText( service.serviceType.valueSuffix, $languageTagStore )
                                        : ''
                                    }
                                </span>
                            </div>
                        { /if }
                    { /if }
                { /each }
            </div>

            <div class="mobile-select-plan">
                <button
                    class:is-active={ planByIdMap[ selectedTab ].id === $selectedPlan?.id || '' }
                    on:click={ () => handleSelectPlan( planByIdMap[ selectedTab ] ) }
                    class="select-button"
                >
                    { getLocalizedText( $textStore[ 'select-button' ] || '', $languageTagStore ) }
                </button>
            </div>
        </div>
    </div>

    <p class="helper-text">{ getLocalizedText( $textStore[ 'montants-affiches-hors-taxes-label' ] || '', $languageTagStore ) }</p>
{ /if }
