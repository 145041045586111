<script>
	import { getProcessedMultilineTranslatedText } from '$lib/base.js';
    // -- IMPORTS

    import { getImagePath } from '$lib/filePath';
    import { getLocalizedText } from 'senselogic-gist';
    import { enabledAdminView } from '$lib/store/adminStore.js';
    import AdminEditButton from '$lib/component/element/AdminEditButton.svelte';
    import { languageTagStore } from '$lib/store/languageTagStore';
    import { textStore } from '$lib/store/textStore';
    import AnimatedContainer from '$lib/component/element/AnimatedContainer.svelte';
    import AnimatedText from '$lib/component/element/AnimatedText.svelte';
    import { pageStore } from '../../store/pageDataStore';
    import { fetchData } from '../../base';
    import { onMount } from 'svelte';
    import ContactButtons from '../element/ContactButtons.svelte';
    import urlStore from '$src/lib/store/urlStore';
    import FullLoading from '../Layout/FullLoading.svelte';

    // -- VARIABLES

    export let founderArray = [];
    let founderImage = $pageStore.block.FoundersSection;
    let isLoading = true;

    // -- FUNCTIONS

    async function loadData(
        )
    {
        try
        {
            isLoading = true

            let data =
                await fetchData(
                    '/api/block/get-by-slug',
                    {
                        method: 'POST',
                        body: JSON.stringify(
                            {
                                slug: "founder-image-path",
                            }
                        ),
                        headers: { 'Content-Type': 'application/json' }
                    }
                    );

            $pageStore.block.FoundersSection = data.blockArray[ 0 ]
            founderImage = data.blockArray[ 0 ]
        }
        catch ( error )
        {
            console.error( 'Error:', error )
        }
        finally
        {
            isLoading = false;
        }
    }

    // -- STATEMENTS

    onMount(
        () =>
        {
           if( founderImage === undefined ) {
                loadData()
            }
            else
            {
                isLoading = false;
            }
        }
    )

    $:  if ( isLoading === false && $urlStore.hash === '#founders' )
        {
            let element = document.querySelector( '#founders' );

            if ( element )
            {
                element.scrollIntoView();
            }
        }
</script>

<style lang="stylus">
    // -- IMPORTS

    @import '../../../constant.styl';
    @import '../../../mixin.styl';

    // -- CLASSES

    .founders-section
    {
        border: 1px solid borderColor;
        padding: 5rem 1.5rem;

        display: flex;
        flex-direction: column;
        gap: 2rem;

        +media( desktop )
        {
            margin: 0;
            max-width: 77vw;
            border: none;
            padding-bottom: 2.5rem;

            gap: 4rem;
        }
    }

    .founders-container
    {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        align-items: flex-start;
        align-self: stretch;
    }

    .founders-content
    {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        align-items: flex-start;
        align-self: stretch;

        +media( desktop )
        {
            width: 50%;

            flex: 1;
        }
    }

    .founders-image
    {
        height: 100%;
        width: 100%;
        aspect-ratio: 4 / 3;

        object-fit: cover;

        +media( desktop )
        {
            height: 50rem;
        }
    }

    .founders-content-heading-title
    {
        line-height: 3rem;
        font-size: 2rem;
        font-weight: 600;
        color: whiteColor;
    }

    .founders-content-heading-subtitle
    {
        line-height: 2rem;
        font-size: 1.25rem;
        color: #E6E9EE;
    }

    .founders-content-wrapper
    {
        display: flex;
        flex-direction: column;
        gap: 2rem;

        +media( desktop )
        {
            flex-direction: row;
            gap: 5rem;
        }
    }
</style>

<section class="founders-section" id="founders">
    { #if isLoading }
        <FullLoading />
    { :else }
        <span class="heading-title color-light-gold" class:is-admin-enabled={ $enabledAdminView }>
            <AnimatedText text={ getLocalizedText( $textStore[ 'founders-label' ], $languageTagStore ) } />
            <AdminEditButton
                hash="founders-label"
                inset="-2.5rem -1rem auto auto"
            />
        </span>

        <div class="founders-container" class:is-admin-enabled={ $enabledAdminView }>
            <div 
                class="founders-image" 
                style="
                    background: URL( { getImagePath( founderImage.imagePath, '1920' ) } ) no-repeat center center / cover, 
                                URL( { getImagePath( founderImage.imagePath, '360' ) } ) no-repeat center center / cover; 
                    "
            />
            <AdminEditButton
                type="block"
                id={ founderImage.id }
            />

            <div class="founders-content-wrapper">
                { #each founderArray as { id, name, position, biography, emailAddress, linkedinLink } }
                    <div class="founders-content" class:is-admin-enabled={ $enabledAdminView }>
                        <AdminEditButton
                            hash={ id }
                        />
                        <div>
                            <p class="founders-content-heading-title"><AnimatedText text={ name }/></p>
                            <p class="founders-content-heading-subtitle"><AnimatedText text={ getLocalizedText( position, $languageTagStore ) }/></p>
                        </div>

                        <AnimatedContainer>
                            <div class="description-text">
                                { @html getProcessedMultilineTranslatedText( biography || '', $languageTagStore ) }
                            </div>
                        </AnimatedContainer>

                        <AnimatedContainer>
                            <ContactButtons
                                { emailAddress }
                                { linkedinLink }
                            />
                        </AnimatedContainer>
                    </div>
                { /each }
            </div>
        </div>
    { /if }
</section>
