<script>
	import { getImagePath } from '$lib/filePath';
    // -- IMPORTS

    import { getLocalizedText } from 'senselogic-gist';
    import { languageTagStore } from '$lib/store/languageTagStore';

    // -- VARIABLES

    export let markerIndex = 0;
    export let marker;
    export let scale = 1;
    let markerCardContainerElement;

    // -- FUNCTIONS

    function handleMouseEnterEvent(
        event
        )
    {
        markerCardContainerElement.classList.add( 'is-hover' );
    }

    // ~~

    function handleMouseLeaveEvent(
        event
        )
    {
        markerCardContainerElement.classList.remove( 'is-hover' );
    }
</script>

<style lang="stylus">
    // -- IMPORTS

    @import '../../../constant.styl';
    @import '../../../mixin.styl';

    // -- CLASSES

    .is-hover
    {
        opacity: 1;
        transform: translateY( 0 );
        visibility: visible;
        pointer-events: none;
        display: inherit;
    }

    .marker-card-container:not( .is-hover )
    {
        opacity: 0;
        transform: translateY( 25px );
        visibility: hidden;
        pointer-events: none;
        display: none;
    }

    .marker-container
    {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        gap: -40px;
        flex-shrink: 0;
        font-size: 16px;
        position: absolute;
        transform: translate( -50%, -100% );
        cursor: pointer;
        transition: transform 400ms ease-in-out, opacity 500ms ease-in-out, visibility 400ms ease-in-out;
    }

    .marker-card-container
    {
        display: flex;
        flex-direction: column;
        align-items: center;
        z-index: 2;
    }

    .marker-content
    {
        display: flex;
        transform: rotate(-45deg);
        padding: 1px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border-radius: 16px;
        background: rgba( 173, 166, 144, 0.00 );
        animation: pulse 2s ease-in-out infinite;
        animation-delay: calc( var( --animation-delay ) * 0.5s );
        transition: padding 400ms ease-in-out, background 400ms ease-in-out;
    }

    .marker-content:hover
    {
        padding: 2px;
        background: rgba( 173, 166, 144, 0.40 );
        animation: none;
    }

    .marker-content:hover .marker
    {
        padding: 4px;
    }

    .marker
    {
        display: flex;
        padding: 0.18rem;
        align-items: center;
        gap: 8px;
        border-radius: 16px;
        background: lightGoldColor;
        transition: padding 400ms ease-in-out;
    }

    .marker-card
    {
        display: flex;
        padding: 16px;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        border-radius: 20px;
        background: darkGreyColor;
        z-index: 2;
        border: 1px solid lightGoldColor;
    }

    .marker-card-image
    {
        align-self: stretch;
        border-radius: 10px;
        height: 100px;
        width: 168px;
    }

    .marker-card-description
    {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
    }

    .title
    {
        color: lightGoldColor;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 34px;
        letter-spacing: 0.24px;
    }

    .subtitle
    {
        align-self: stretch;
        color: vlightGreyColor;
        font-size: 18px;
        line-height: 32px;
    }

    .marker-card-polygone
    {
        z-index: 1;
        margin-top: -16px;
        width: 48px;
        height: 48px;
        fill: linear-gradient( 0deg, rgba( 0, 0, 0, 0.20 ) 0%, rgba( 0, 0, 0, 0.20 ) 100% ), #000;
    }

    // -- MIXINS

    @keyframes pulse
    {
        0%, 100%
        {
            background: rgba(173, 166, 144, 0.00);
            border: 0px solid rgba(173, 166, 144, 0);
        }

        50%
        {
            background: rgba(173, 166, 144, 0.50);
            border: .5px solid rgba(173, 166, 144, 1);
        }
    }
</style>

<div class="marker-container" style="left: { marker.x }%; top: { marker.y }%">
    <div class="marker-card-container" bind:this={ markerCardContainerElement }>
        <div class="marker-card">
            { #if marker.imagePath }
                <div class="marker-card-image" style="background: url( { getImagePath( marker.imagePath ) } ) lightgray 50% / cover no-repeat;"/>
            { /if }
            <div class="marker-card-description">
                { #if marker.name }
                    <p class="title">{ getLocalizedText( marker.name, $languageTagStore ) }</p>
                { /if }

                { #if marker.subtitle }
                    <p class="subtitle">{ marker.subtitle }</p>
                { /if }
            </div>
        </div>
        <svg class="marker-card-polygone" xmlns="http://www.w3.org/2000/svg" width="42" height="37" viewBox="0 0 42 37" fill="none">
            <path d="M21 36.3726L0.215396 0.372555L41.7846 0.372558L21 36.3726Z" fill="black"/>
            <path d="M21 36.3726L0.215396 0.372555L41.7846 0.372558L21 36.3726Z" fill="black" fill-opacity="0.2"/>
        </svg>
    </div>
    <div class="marker-content" style="--animation-delay: { markerIndex };">
        <div class="marker" on:mouseenter={ handleMouseEnterEvent } on:mouseleave={ handleMouseLeaveEvent }>
            <!-- <svg class="logo" xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                <path d="M6.61099 0.0147095H11.5V4.90372H6.61099V0.0147095ZM6.61099 6.1257H11.5V11.0147H6.61099V6.1257ZM0.5 0.0147095H2.9445C4.29448 0.0147095 5.38901 1.10924 5.38901 2.45921C5.38901 3.80919 4.29448 4.90372 2.9445 4.90372H0.5V0.0147095ZM0.5 6.1257H2.9445C4.29448 6.1257 5.38901 7.22023 5.38901 8.57021C5.38901 9.92018 4.29448 11.0147 2.9445 11.0147H0.5V6.1257Z" fill="#1B1B1B"/>
            </svg> -->
        </div>
    </div>
</div>